import i18n from "i18next";
import en from "./locales/en.json";
import gu from "./locales/gu.json";
import hi from "./locales/hi.json";
import mr from "./locales/mr.json";

import { initReactI18next } from "react-i18next";
let lng = "en";
if (localStorage.getItem("title")) {
  if (localStorage.getItem("title") === "English") {
    lng = "en";
  }
  if (localStorage.getItem("title") === "ગુજરાતી") {
    lng = "gu";
  }
  if (localStorage.getItem("title") === "हिन्दी") {
    lng = "hi";
  }
  if (localStorage.getItem("title") === "मराठी") {
    lng = "mr";
  }
} else {
  lng = "en";
}
i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: en,
    },
    gu: {
      translations: gu,
    },
    hi: {
      translations: hi,
    },
    mr: {
      translations: mr,
    },
  },
  fallbackLng: lng,
  debug: true,

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
